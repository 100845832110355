// ========================================================
// =================== CUSTOM THEMES ======================
// ========================================================
@import '~@angular/material/theming';
@include mat-core();
 
$md-myappprimary: (
    50 : #e0f3f0,
    100 : #b3e1db,
    200 : #80cdc3,
    300 : #4db8aa,
    400 : #26a998,
    500 : #009a86,
    600 : #00927e,
    700 : #008873,
    800 : #007e69,
    900 : #006c56,
    A100 : #9cffe6,
    A200 : #69ffda,
    A400 : #36ffcd,
    A700 : #1cffc7,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-myappsecundary: (
    50 : #f8f8ec,
    100 : #ececd1,
    200 : #e0e0b2,
    300 : #d4d493,
    400 : #caca7b,
    500 : #c1c164,
    600 : #bbbb5c,
    700 : #b3b352,
    800 : #abab48,
    900 : #9e9e36,
    A100 : #ffffea,
    A200 : #ffffb7,
    A400 : #ffff84,
    A700 : #ffff6a,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-myappinfo: (
    50 : #eaf0ff,
    100 : #cadbff,
    200 : #a7c3ff,
    300 : #84aaff,
    400 : #6998ff,
    500 : #4f86ff,
    600 : #487eff,
    700 : #3f73ff,
    800 : #3669ff,
    900 : #2656ff,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #d4dcff,
    A700 : #bac8ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-myappwarn: (
    50 : #fff4e2,
    100 : #ffe3b6,
    200 : #ffd186,
    300 : #ffbe55,
    400 : #ffb030,
    500 : #ffa20c,
    600 : #ff9a0a,
    700 : #ff9008,
    800 : #ff8606,
    900 : #ff7503,
    A100 : #ffffff,
    A200 : #fff7f2,
    A400 : #ffd9bf,
    A700 : #ffc9a6,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-myapperror: (
    50 : #ffeeef,
    100 : #ffd5d6,
    200 : #ffb9bb,
    300 : #ff9ca0,
    400 : #ff878b,
    500 : #ff7277,
    600 : #ff6a6f,
    700 : #ff5f64,
    800 : #ff555a,
    900 : #ff4247,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffeaea,
    A700 : #ffd0d1,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

 //Light Theme
$my-app-primary: mat-palette($md-myappprimary, 500);
$my-app-secundary: mat-palette($md-myappsecundary, 600);
$my-app-warn: mat-palette($md-myappwarn, 500);
$my-light-theme: mat-light-theme($my-app-primary, $my-app-secundary, $my-app-warn);
// @include angular-material-theme($my-light-theme);

@function my-mat-light-theme-foreground($color) {
    @return (
        base:              $color,
        divider:           $black-12-opacity,
        dividers:          $black-12-opacity,
        disabled:          rgba($color, 0.38),
        disabled-button:   rgba($color, 0.38),
        disabled-text:     rgba($color, 0.38),
        hint-text:         rgba($color, 0.38),
        secondary-text:    rgba($color, 0.54),
        icon:              rgba($color, 0.54),
        icons:             rgba($color, 0.54),
        text:              rgba($color, 0.9),
        slider-min:        rgba($color, 0.87),
        slider-off:        rgba($color, 0.26),
        slider-off-active: rgba($color, 0.38),
    );
};

$my-foreground: my-mat-light-theme-foreground(mat-color($my-app-primary, 700));
$my-app-theme-custom: map-merge($my-light-theme, (foreground: $my-foreground));
@include angular-material-theme($my-app-theme-custom);


//Dark Theme
$my-app-dark-primary: mat-palette($md-myappprimary, A700);
$my-app-dark-secundary: mat-palette($md-myappsecundary, A100);
$my-app-dark-warn: mat-palette($md-myappwarn, A700);
$my-dark-theme: mat-dark-theme($my-app-dark-primary, $my-app-dark-secundary, $my-app-dark-warn);

// .light-theme {
//     @include angular-material-theme($my-light-theme);
//     color: #222;
// }

.dark-theme {
    @include all-component-colors($my-dark-theme);
    color: #fff;
} 
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }