html,
body {
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

/* snackbar estilo - exito */
.succes-snack {
  background-color: #43a047;
  color: #fff;
}

/* snackbar estilo - advertencia */
.warn-snack {
  background-color: #ffa000;
  color: #fff;
}

/* snackbar estilo - error */
.error-snack {
  background-color: #d32f2f;
  color: #fff;
}

/* snackbar estilo - información */
.info-snack {
  background-color: #1976d2;
  color: #fff;
}

/* Modificando los scroll bar */
::-webkit-scrollbar {
  width: 5px;
  overflow-y: scroll;
  background: #dfdfdf;
  /* box-shadow: inset 0 0 4px #dfdfdf; */
}

//Diseño de las tablas - Material
.mat-row:nth-child(even) {
  background-color: #fff;
}

// Disello de las tablas - Material
.mat-row:nth-child(odd) {
  background-color: #f5f5f5;
}

// Colore sdel scrollbar
::-webkit-scrollbar-thumb {
  background: #80cdc3;
  border-radius: 8px;
}
 
//Contenedor del dashboard
.root-dashboard {
  background-color: #bdc3c7;
  min-height: calc(100% - 80px);
  width: -webkit-fill-available;
}

//Espaciado para las tarjetas
.space-dashboard {
  height: 24px;
}

//Contenedor de las tarjetas
.container-dashboard {
  height: 100%;
  padding-left: 16px;
  padding-right: 16px;
}

// Estilos de los botones
.btn-block {
  color: #fff !important;
  font-size: large;
  padding: 8px 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.container-spinner {
  height: 100%;
  padding: 24px 16px;
  min-height: calc(100vh - 80px);
}


//Mensaje de error para los formularios
.error-message-form {
  margin-left: 8px;
  margin-right: 8px;
  padding-top: 4px;
}

.table-container {
  width: 100%;
  overflow-x: auto;
}

//Estilos para la tabla
.table-data {
  width: 100%;
}

//Tamaño minimo cuando la tabla es pequeña
@media (max-width: 1100px) {
  .table-data {
    width: 950px !important;
  }
}

//Puntos suspensivos cuando alcanza un tamaño superior al de la celda 
td {
  max-width: 60px; /* feel free to include any other value */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 4px !important;
}

.text-align-table {
  text-align: center !important;
}

//Botones de acciones de la tabla
.fab-table-button {
  background-color: transparent !important;
  box-shadow: none !important;
  margin-right: 8px;
}

.fab-table-button:hover {
  opacity: 0.5;
}

.fab-table-button-disable:hover {
  opacity: 1;
}

.fab-icon-button-disable {
  color: #dcdcdc !important;
}

.alingn-text-table {
  text-align: center !important;
}

// ===========================================================
// ===========================================================
//CONTENEDORDEL ORMULARIO PARA EDICION - VISUALIZACIÓN O CORRECCION
.root-crud {
  background-color: #bdc3c7;
  min-height: calc(100%);
  width: -webkit-fill-available;
}

.container {
  height: 100%;
  padding-left: 16px;
  padding-right: 16px;
}

.container-form {
  margin-top: 32px;
}

.title-form-text {
  margin-top: 24px !important;
  margin-left: 16px;
}

.imput-form-text {
  margin-left: 8px;
  margin-right: 8px;
  margin-top: 16px !important;
  margin-bottom: 0px !important;
}

.error-message-form {
  margin-left: 8px;
  margin-right: 8px;
  padding-top: 0px;
}

.container-spinner {
  height: 100%;
  padding: 24px 16px;
  min-height: calc(100vh);
}
